import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import { createMetaManager } from "vue-meta";

const app = createApp(App);

// Benutzen Sie vue-meta
// app.use(createMetaManager());

// Router hinzufügen
app.use(router);

// App einbinden
app.mount("#app");
