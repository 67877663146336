<template>
  <div>
    <ProjectItem
      v-for="project in projects"
      :key="project.id"
      :project="project"
    />
  </div>
</template>

<script>
import ProjectItem from "@/components/ProjectItem.vue";

export default {
  name: "Projects",
  components: {
    ProjectItem,
  },
  data() {
    return {
      projects: []
    }
  },
  async mounted() {
    await this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await fetch('http://localhost:3000/projects');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        this.projects = await response.json();
      } catch (error) {
        console.error("There was an error fetching the projects:", error);
      }
    }
  }
};
</script>
