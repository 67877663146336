<template>
  <Projects />
</template>

<script>
import Projects from "@/components/Projects.vue";

export default {
  name: "HomeView",
  components: {
    Projects,
  },
};
</script>
