import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Projects from '../components/Projects.vue'
import Detail from '../views/Detail.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/projects/:id',
    name: 'Detail',
    component: Detail,
    props: true // Ermöglicht die Übergabe der Route-Parameter als Props an die Komponente
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Setzen Sie den Dokumententitel zu "Seitenname - FOSSProjects"
  document.title = `${to.name} - FOSSProjects`;
  next();
});

export default router;
