<template>
  <div v-if="project">
    <h2>{{ project.name }}</h2>
    <p>{{ project.description }}</p>
    <!-- Weitere Projektdetails hier -->
  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      project: null
    }
  },
  async mounted() {
    await this.fetchProjectDetail();
  },
  methods: {
    async fetchProjectDetail() {
      const projectId = this.$route.params.id; // Angenommen, die Route enthält die ID
      try {
        const response = await fetch(`http://localhost:3000/projects/${projectId}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        this.project = await response.json();
      } catch (error) {
        console.error("There was an error fetching the project detail:", error);
      }
    }
  }
};
</script>
