<template>
  <div id="app">
    <header>
      <nav>
        <router-link to="/">Home</router-link>
        |
        <router-link to="/about">About</router-link>
        |
        <router-link to="/projects">Projects</router-link>
      </nav>
      <div class="text">
        <div class="">
          <h1>Foss Projects</h1>
        </div>
        <div class="">
          <h3>Free and Open Source Software</h3>
        </div>
        <div class="">
          <h4>Made in Germany</h4>
        </div>
      </div>
    </header>
    <router-view />
  </div>
</template>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
header {
  background-image: url("./assets/header.webp");
  width: 100%;
  height: 50vh; /* Höhe des Headers */
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Vertikale Ausrichtung */
  align-items: center;
  padding-top: 30px; /* Fügt oben im Header Platz hinzu */
  position: relative; /* Stellt sicher, dass das Pseudo-Element bezüglich des Headers positioniert wird */
}

header::before {
  content: "";
  display: block;
  background-color: rgba(0,0,0,.5); /* Dunkler Überlagerungseffekt */
  position: absolute; /* Absolute Positionierung innerhalb des Headers */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Stellt sicher, dass die Überlagerung hinter den anderen Inhalten liegt */
}

nav {
  width: 100%;
  z-index: 2;
}

header .text {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1; /* Nimmt verbleibenden Platz ein und zentriert den Inhalt */
  margin: 30px 0; /* Fügt oben und unten einen Abstand hinzu */
  z-index: 2;
  flex-direction: column;
}

html {
  overflow-x: hidden;
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  background-color: #111;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  min-height: 100svh;
}

nav a {
  font-weight: bold;
  color: lightblue;
}

nav a.router-link-exact-active {
  color: lightsalmon;
}
</style>
