<template>
  <router-link :to="{ name: 'Detail', params: { id: project.id } }" class="projects">
    <div>
      {{ project.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProjectItem",
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
  a.projects {
    color: white;
    text-decoration: none;
    font-size: 2.2rem;

  }
</style>